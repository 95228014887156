footer {
    padding: 60px 0 20px;
    color: rgba(255, 255, 255, 1.00);
    @media @tablet {
        padding: 20px 0;
    }
    @media @mobile {
        padding-left: 0;
        padding-right: 0;
    }
}
.footer__container {
    .container;
    display: flex;
    flex-wrap: wrap;
    @media @tablet {
        display: block;
        .social-icons {
            justify-content: center;
        }
    }
}
.footer__col {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    @media @tablet {
        display: block;
        margin: 0 auto 30px;
        text-align: center;
    }
}
.footer__logo {
    width: 100px;
    height: 40px;
    background-color: #BB1618;
    padding: 10px;
}
.footer__text {
    font-size: .8rem;
    margin: 0;
}
.contact__link {
    color: #FFF;
    text-decoration: none;
    font-size: .8rem;
    display: block;
    flex: 1 0 100%;
    margin-bottom: 10px;
    &:last-of-type {
        margin-bottom: 0;
    }
    span {
        color: @c-main;
    }
}
.footer-top {
    background-color: #FF0D13;
    height: 40px;
}
.footer-middle {
    min-height: 75px;
    background-color: white;
    border-bottom: 5px solid #ebebeb;
}

.footer-middle__container {
    padding-top: 10px;
    padding-bottom: 10px;
    @media @tablet {
        display: flex;
        flex-wrap: wrap;
        width: auto;
    }
}

.footer-img {
    display: inline-block;
    margin-right: 40px;
    @media @tablet {
        flex: 1 1 ~"calc(25% - 40px)";
        height: auto;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media @mobile {
        flex: 1 1 ~"calc(33.33333% - 40px)";
    }
    @media @mobile-s {
        flex: 1 1 ~"calc(50% - 40px)";
    }
}

.footer-img__logo {
    margin: 0;
}

.link-list__title {
    color: #b51917;
    font-weight: 600;
    border-bottom: solid #b51917 1px;
    font-size: 0.9rem;
    margin-bottom: 5px;
    &:after {
        border-bottom: solid red 1px;
        width: 100%;
    }
}
.link-list__item {
    line-height: 1.375rem !important;
}
.link-list__text {
    text-decoration: none !important;
    color: black;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.875rem;
}
.pages {
    list-style-type: none;
    padding-left: 0;
}
.footer-image {
    display: inline-block;
    width: 150px;
    height: 60px;
}
.footer__title-text {
    color: #b51917;
    font-weight: 600;
    border-bottom: solid #b51917 1px;
    font-size: 0.9rem;
    margin-bottom: 15px;
}
.footer__content-text {
    p {
        color: black;
        font-size: 0.8rem;
        line-height: 1.3rem;
        font-weight: 400;
    }
}
.footer__social-icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
        cursor: pointer;
    }
}
.footer__terms-text {
    color: black;
    font-size: 0.6rem;
}
