.form-group__row {
    display: flex;
    margin: 0 -10px;
    .form-group {
        flex: 1;
        padding: 0 10px;
    }
}

.form-group {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.form-group__label {
    position: absolute;
    left: 0;
    font-size: .8rem;
    font-weight: 200;
    top: 0;
    will-change: transform;
    .transition;
}

.form-group__input {
    background: none;
    border: 0;
    outline: none;
    border-bottom: 1px solid #FFF;
    width: 100%;
    color: black;
    font-family: @font-sans-serif;
    padding: 0 0 10px;
    appearance: none;
    border-radius: 0;
    border: solid lightgrey 1px;
    padding: 10px;
    font-size:1.1rem;

    &:focus {
        border-color: #BA1919;

        ~ .form-group__label {
            transform: translateY(-20px) scale(.8);
        }
    }

    &::placeholder {
        color: #FFF;
        font-size: .7rem;
    }
}

.form-group__textarea {
    .form-group__input;
    height:150px;
}

.form-group__submit {
    -webkit-appearance: none;
    .button;
    background-color: #BA1919;

    &:hover {
        background-color: fade(#BA1919,90%);
    }
}

.select-container {
    width: 100%;
    position: relative;
    &:after {
        content: '\f078';
        font-family: @font-awesome;
        font-weight: 900;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }
    select {
        appearance: none;
    }
}

