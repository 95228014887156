.panel {
    margin: @content-padding;
}
.panels {
    padding: 35px;
}
.panel--padding {
    margin: 0;
    padding: @content-padding;
}
.panel--full-width {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    .col-6 {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .panel {
        padding: 100px 0;
        margin: 0;
        justify-content: flex-start !important;
    }
    picture {
        flex: 1;
        height: 100%;
        width: 100%;
    }
}
.panel--full-width-reverse {
    flex-direction: row-reverse;
}
.panel--grey {
    background-color: #eee;
}
.panel--image-block {
    position: relative;
    min-height: 340px;
    max-width: 400px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    margin-right: 0;
    margin: 0;
    .panel__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .panel-body {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: fade(#4b7995, 85%);
        color: white;
        padding: 50px;
        @media @mobile {
            padding: 30px;
        }
    }
    .panel-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
        line-height: 1.625rem;
    }
    .panel__content {
        font-weight: 100;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    p {
        font-weight: 100;
    }
}
.panel--block-title {
    text-transform: uppercase;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: bold;
    flex-basis: 100%;
    /*margin-left:25px;*/
    margin-top: 10px !important;
    /*margin-bottom:20px;*/
    padding-bottom: 20px;
    margin-bottom: 0;
}
.project-panels {
    padding: 30px 0;
    padding-top: 40px;
}
/*.project-panels{
    padding:35px;

    @media @mobile{
        padding:0;
        padding-top:20px;
        padding-bottom:20px;
    }
    /*.container{
        @media @mobile{
            padding:0;
        }
    }*/
//}
