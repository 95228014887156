.homepage {
    display: flex;
    height: 100vh;
    @media @tablet {
        flex-direction: column;
    }
}

.homepage--active {
    .homepage-panel {
        opacity: 1;
    }
    .homepage__logo {
        opacity: 1;
    }
}

.homepage__logo {
    width: 300px;
    height: 88px;
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 1;
    opacity: 0;
    .transition--long;
    @media @tablet {
        display: none;
    }
}

.homepage-panel {
    flex: 0 1 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    opacity: 0;
    transition: all cubic-bezier(0.63, 0.11, 0.3, 0.91) 1s;
    transition-delay: .3s;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: fade(@c-grey, 90%);
        z-index: 1;
        opacity: 0;
        will-change: transform;
        .transition;
    }
    &:hover {
        &:after {
            opacity: 1;
        }
        .homepage-panel__img {
            transform: scale(1.05);
        }
    }
    &:nth-of-type(2) {
        transition-delay: .6s;
    }
}

.homepage-panel__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    will-change: transform;
    transform: scale(1.1);
    .transition--long;
}

.homepage-panel__content {
    position: relative;
    z-index: 3;
}

.homepage-panel__title {
    font-size: 8vw;
    font-weight: 400;
    // margin-bottom: 20px;
    margin-bottom: 0;
    @media @tablet {
        font-size: 5.5rem;
    }
}

.homepage-panel__text {
    font-size: 2vw;
    @media @tablet {
        font-size: 1.2rem;
    }
}

.homepage-panel__icon {
    z-index: 2;
    height: 50%;
    width: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    svg {
        width: 100%;
        height: 100%;
    }
}

.homepage-panel__icon--active {
    display: block;
}