﻿.carousel {
    background-color: #3f4047;
}
.carousel__container {
    // padding: 0;
    position: static;
    display: flex;
    @media @desktop-wide-min {
        padding-left: 50px + @layout-padding;
    }
}
.carousel__content {
    padding: 50px 0;
    color: #fff;
    z-index: 3;
    position: relative;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    padding-top: 170px;
    .transition;
}

.carousel__title {
    font-size: 3.4375rem;
    font-weight: 400;
    line-height: 1.2;
    // line-height: 4.0625rem;
    @media @tablet {
        font-size: 2.5rem;
    }
    @media @mobile {
        font-size: 1.8rem;
    }
    p {
        color: black;
    }
}
.carousel__text {
    margin-top: auto;
    font-size: 0.9rem;
    @media @mobile {
        padding-right: 60px;
        font-size: 0.8rem;
    }
}
.carousel__slide {
    min-height: 650px;
    height: 100%;
    width: 100%;
    // padding-top: 35%;
    position: relative;
    color: #fff;
    display: flex;
    @media @tablet {
        min-height: 500px;
    }
}
.carousel__img {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 1;
}
.owl-carousel {
    .owl-item {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: @c-dark-blue;
            opacity: .3;
            z-index: 2;
            .transition;
        }
        &:not(.active) {
            &:after {
                opacity: 0.7;
            }
            .carousel__content {
                opacity: 0;
            }
            .link--cover {
                display: none;
            }
        }
    }
    .owl-dots {
        position: absolute;
        bottom: 3%;
        // left: 4%;
        left: 50%;
        right: auto;
        margin-left: -(@container-width / 2) + @layout-padding;
        .owl-dot {
            background: none;
            border: 0;
            outline: none;
            margin: 0 5px;
            padding: 0;
            &.active {
                span {
                    background: #fff;
                    border-color: #fff;
                    border-radius: 50%;
                    width: 12px;
                    height: 12px;
                    transition: all 0.2s ease;
                }
            }
            span {
                background: none;
                border: 0.5px solid #fff;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: rgba(255, 255, 255, 0.5);
                transition: all 0.2s ease;
            }
        }
    }
    .owl-next {
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        left: auto;
        @media @mobile {
            top: auto;
            bottom: 18px;
            right: 20px;
        }
        img {
            margin: 0;
        }
    }
}
