.article-page {
    margin: 100px 0;
    @media @tablet {
        margin: 60px 0;
    }
}

.article__date {
    color: @c-main;
    margin-bottom: 15px;
    font-size: .8rem;
    display: block;
}

.article__title {
    margin-bottom: 100px;
    @media @tablet {
        margin-bottom: -50px;
    }
    @media @mobile {
        font-size: 2rem;
    }
}