﻿.project-panel__list {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    padding: 0;
    margin: 0 -@layout-padding;

    @media @mobile {
        display: block;
        margin: 0; 
    }
}

.project-panel__item {
    flex: 0 1 ~"calc(33.33333% - (@{layout-padding} * 2))";
    margin: 0 @layout-padding (@layout-padding * 2);
    position: relative;
    overflow: hidden;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        z-index: 10;
        pointer-events: none;
        .transition;
    }

    &:hover {
        &:after {
            opacity: .1;
        }

        .panel__image {
            transform: scale(1.1);
        }
    }

    @media @tablet {
        flex: 1 1 30%;
        /*max-width: 340px;*/
        // max-width:340px;
    }

    @media @mobile {
        /*flex: 1 1 100%;*/
        /*margin: 0;*/
        margin: 0 0 25px 0; 
        /*margin-bottom: 25px;*/
        
    }
}

.projects__panel {
    padding: 40px 0;
    background-color: #DBE4EA;
    @media @mobile {
        /*padding: 25px;*/
        padding-top: 35px;
    }
}

.panel__image {
    will-change: transform;
    .transition;
}