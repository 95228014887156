.transition {
	transition: all ease .2s;
}

.transition--long {
	transition: all ease .3s;
}

.link--cover {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
}

.text-align--left {
	text-align: left;
}

.no-padding {
	padding: 0;
}

.no-margin {
	margin: 0;
}

.no-scroll {
	overflow: hidden;
}

.spacer {
	display: block;
	margin: 80px 0;
}




@keyframes fade_in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}