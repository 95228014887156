.homePage {
    .menu-trigger__line {
        border-color: #FFF;
        &:after {
            border-color: #FFF;
        }
    }

    header {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        right: 0;
    }
    .header__container {
        &:not(.header--bg) {
            background: none;
            box-shadow: none;
            .cls-1, .cls-2 {
                fill: #FFF;
            }
        }
        &.header--bg {
            .menu-trigger__line {
                border-color: grey;
                &:after {
                    border-color: grey;
                }
            }
            .menu-trigger--active {
                .menu-trigger__line {
                    border-color: #FFF;
                    &:after {
                        border-color: #FFF;
                    }
                }
            }
        }
    }
}
.header__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .transition;
    @media @tablet {
        position: fixed;
        background-color: #FFF;
        top: 0;
        z-index: 10;
        box-shadow: 0 0 30px 0 rgba(0,0,0,.1);
    }
}
.main-banner__container {
    // background-color: #4A7994;
    height: 220px;
    position: relative;
    .container;
    width: 100%;
    // margin-bottom: 30px;
    @media @tablet {
        height: 200px;
    }
}
.main-banner {
    /*background-color: #4A7994;*/
    position: relative;
    margin-bottom: 30px;
    background-color: #919ea5;
    bottom: 0;
    // margin: 0 0 30px 0;
}
/*.gatewayPage {
    .main-banner {
        margin-bottom: 0;
    }
}*/
.main-banner--project {
    position: relative;
    margin-bottom: 30px;
    // margin: 30px 0;
}
.main-banner--project__background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: red;
    opacity: 0.75;
    max-height: 340px;
}
body.project {
    .main-banner__container {
        height: 340px;
    }
}
.header-title__container {
    width: 60%;
    bottom: 0;
    // left: 3%;
    position: absolute;
    margin: 30px 0;

    @media @tablet{
        margin: 0;
        bottom:30%; 
        width: auto;
    }
}
.header-title {
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    /*bottom: 6%;*/
    margin-bottom: 0;
    line-height: 1.1;
    /*position: absolute;*/
    font-size: 2.25rem;
    /*bottom:0;
    margin: 30px 0;*/
    @media @tablet {
        font-size: 2rem;
    }
    @media @mobile {
        font-size: 1.5rem;
    }
}
.breadcrumb-container {
    /*position:absolute; 
    float:right; 
    bottom: 0;*/
    background-color: #FFF;
    // max-width: 230px;
    // width: 100%;
    /*float:right;*/
    position: absolute;
    bottom: 0;
    right: -6px;
    /*right: -20px;*/

    @media @tablet{
       left:0;
       right:unset;
    }
    @media @mobile {
        
    }
}
.breadcrumb {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 20px 20px 0;
    @media @tablet {
        padding: 20px 30px 0;
    }
    @media @mobile {
        padding: 20px 20px 0;
    }
}
.breadcrumb-item {
    border: solid 1px lightgrey;
    color: lightgrey;
    text-transform: uppercase;
    padding: 5px 15px;
    list-style-type: none;
    margin-right: 20px;
    min-width: 160px;
    position: relative;
    &:last-of-type {
        margin-right: 0;
    }
    @media @mobile {
        min-width: 0;
    }
}
.breadcrumb-text {
    color: grey;
    text-decoration: underline;
    font-weight: 400;
    font-size: 1rem;
    @media @mobile {
        font-size: .7rem;
    }
}
.main__banner {
    width: 100%;
}
.header__logo {
    width: 140px;
    height: 120px;
    will-change: transform;
    .transition;
    &:hover {
        transform: scale(.95);
    }
    @media @mobile {
        height: 60px;
        width: 90px;
    }
    .cls-1, .cls-2 {
        @media @tablet {
            .transition;
        }
    }
}
.owl-stage {
    padding-left: 0 !important;
}
.owl-item {
    &:not(.active) {
        .carousel__slide {
            position: relative;
            /*&:after {
                content: '';
                background-image: url("~/img/right-arrow.png");
                background-repeat: no-repeat;
               
                width: 40px;
                height: 40px;
                position: absolute;
                left: 2.5%;
                top: 50%;
               z-index:5;
            }*/
        }
    }
}
.owl-nav {
    .owl-prev {
        display: none;
    }
    .owl-next {
        position: absolute;
        top: 45%;
        left: 95%;
        font-size: 6rem !important;
        color: white !important;
        outline: none;
        font-weight: 100 !important;
        @media @tablet {
            top: 55%;
            left: 92%;
        }
        @media @mobile {
            left: 83%;
        }
    }
}

.menu-trigger {
    height: 30px;
    width: 30px;
    z-index: 101;
    position: relative;
    @media @tablet-min {
        display: none;
    }
    &--active {
        background-color: #BB1618;
        .menu-trigger__line {
            transform: rotate(45deg);
            margin: -1px -10px;
            border-color: #FFF;
            &:after {
                transform: rotate(-90deg);
                border-color: #FFF;
            }
        }
    }
}



.menu-trigger__line {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -5px -10px;
    width: 20px;
    height: 1px;
    border-top: 1px solid grey;
    transform-origin: top;
    will-change: transform;
    .transition;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(5px);
        width: 20px;
        height: 1px;
        border-top: 1px solid grey;
        transform-origin: top;
        will-change: transform;
        .transition;
    }
}