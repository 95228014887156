.news-listing {
  padding: 100px 0;
  @media @tablet {
    padding: 60px 0;
  }
}

.listing-header {
  display: flex;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.listing-header__title {
  margin: 0;
  display: block;
  font-size: 2.5rem;
  @media @tablet {
    flex: 0 1 100%;
  }
}

.listing-header__back {
  font-size: 1.3rem;
  color: @c-body;
  font-weight: 400;
  text-decoration: none;
  .transition;
  &:hover {
    color: @c-main;
  }
}

.listing-header__filters {
  display: flex;
  justify-content: space-between;
  flex: 0 1 auto;
  flex-wrap: wrap;
  @media @tablet {
    justify-content: flex-start;
    margin-top: 20px;
  }
}

.listing-header__filter {
  font-size: .85rem;
  position: relative;
  cursor: pointer;
  margin-right: 30px;

  &:last-of-type {
    margin-right: 0;
  }
  @media @tablet {
    margin: 0 20px 15px 0;
    display: inline-block;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    right: 0;
    border-bottom: 2px solid @c-main;
    will-change: transform;
    transform: scaleX(0);
    .transition;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
    }
  }
}

.listing-header__filter--active {
  color: @c-main;

  &:after {
    display: none;
  }
}

.listing__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.listing__container--archive {
	margin: 0 -20px;
}

.listing__panel {
  flex: 1 1 50%;
  padding: 0 10px;
  margin-bottom: 70px;
  position: relative;
  max-width: 50%;
  @media @tablet {
    margin-bottom: 50px;
  }
  @media @mobile {
    flex: 0 1 100%;
    max-width: 100%;
  }
  &:hover {
    .listing__img-container {
      &:after {
        opacity: .4;
      }
    }
    .listing__img {
      transform: scale(1.05);
	}
	.listing__panel-content {
		transform: translateY(0);
		opacity: 1;
	}
  }
}

.listing__panel--archive {
  flex: 1 1 33.3333%;
  max-width: 33.33333%;
  padding: 0 20px;
  margin-bottom: 40px;
  @media @desktop {
	  flex: 1 1 50%;
	  max-width: 50%;
  }
  @media @mobile {
    flex: 0 1 100%;
    max-width: 100%;
  }
  .listing__img-container {
    margin: 0;
  }
}

.listing__img-container {
  position: relative;
  padding-top: 50%;
  margin-bottom: 20px;
  overflow: hidden;
  color: #FFF;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: @c-main;
    opacity: 0;
    will-change: opacity;
    .transition;
  }
}

.listing__img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
  will-change: transform;
  .transition;
}

.listing__panel-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 20px;
  color: #FFF;
  z-index: 2;
  flex-wrap: wrap;
  .transition;
  transform: translateY(-20px);
  opacity: 0;
  will-change: transform;
}

.listing__date {
  color: @c-main;
  font-weight: 400;
  font-size: .8rem;
  display: block;
  margin-bottom: 25px;
  @media @tablet {
    margin-bottom: 15px;
  }
}

.listing__title {
  margin: 0;
  line-height: 1.5;
  flex: 1 0 100%;
  @media @tablet {
    font-size: 1.2rem;
  }
}

.listing__button {
  margin: 50px auto;
  display: block;
  max-width: 150px;
}
