#map-panel{
    width:100%;
    height:496px;
    // margin-bottom:25px;

   
    @media @mobile{
        height:200px;
        margin-top:20px;
    }
}