﻿.team-members__list {
    display: flex;
    justify-content: center;
    list-style-type: none;
    /*margin: 0 50px 0 50px ;*/

    flex-wrap: wrap;
     margin: 0 -@layout-padding; 
    padding:0;
    @media @mobile {
        display: block;
        margin: 0;
    }
}
.team-members__item {
    flex: 0 1 ~"calc(33.33333% - (@{layout-padding} * 2))";
    margin: 0 @layout-padding (@layout-padding * 2);
    min-width: 330px;
    min-height: 340px;
    /*margin: 0 15px 30px 15px;*/
    /*max-width: 340px;*/
    &:hover {
        cursor: pointer;
    }

    &:hover .panel-body {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &:hover .panel__image {
        filter: brightness(30%);
        opacity: 0.7;
    }

    @media @mobile {
        /*flex: 1 1 100%;*/
        margin: 0;
        margin-bottom: 25px;
    }
}
.team__panel--image-block {
    position: relative;
    min-height: 340px;
    /*max-width: 340px;*/
    /*margin-bottom: 20px;
    margin-top: 10px;*/
    padding: 0;
    margin-right: 0;
    width: 100%;
    margin: 0;

    .panel__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .panel-body {
        /*display: none;*/
        opacity:0;
        visibility:hidden;
        pointer-events:none;
        position: absolute;
        /*top: 0;
        left: 0;
        width: 100%;
        height: 100%;*/
        width: 80%;
        height: 80%;
        margin: 35px;
        z-index: 2;
        background-color: #FFF;
        /*color: red;*/
        padding: 15px;
        transition: all .3s ease;
        /*overflow-y:auto;*/
    }

    .panel-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0;
    }

    .panel__content {
        font-weight: 100;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    p {
        font-size: .9rem;
        line-height: 1rem;
        margin-top:10px;
        font-weight:400;
    }

    h3 {
        color: #b51917;
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
    }

    h4 {
        color: #b51917;
        margin:0;
        font-size: 1rem;
        font-weight:400;
    }
}


.team-members__header {
    padding: 5px;
    border-bottom: solid 1px #919ea5;
}