nav {
    display: flex;
    justify-content: space-between;
    flex: 0 1 400px;
    align-items: center;
    height: 80px;
    @media @tablet {
        display: block;
        position: fixed;
        // left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: #FFF;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, .2);
        height: auto;
        z-index: 100;
        text-align: left;
        // overflow-x: visible;
        width: 100%;
        max-width: 360px;
        .transition--long;
        transform: translateX(120%);
    }
}
.nav--active {
    transform: translateX(0);
}
.nav__container {
    @media @tablet-min {
        display: flex;
    }
    @media @tablet {
        padding: 30px;
        overflow-y: auto;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 48px;
    }
}
.homePage {
    .nav__item {
        color: white;
    }
}
.nav__item-parent {
    margin: 0 0 30px;
    @media @tablet-min {
        margin: 0 40px 0 0;
        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -5px;
            border-bottom: 2px solid #eee;
            .transition;
            transform: scaleX(0);
            transform-origin: left;
            pointer-events: none;
        }
        &:hover {
            &:after {
                transform: scaleX(1);
            }
            .nav__dropdown {
                transform: translateY(0);
                opacity: 1;
                visibility: visible;
            }
        }
    }
    @media @tablet {
        border-bottom: 2px solid rgb(206, 206, 206);
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;;
        &:last-of-type {
            border-bottom: 0;
        }
    }
}
.nav__item {
    color: grey;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    @media @tablet {
        font-size: 1.6rem;
        color: grey !important;
    }
}
.nav__dropdown {
    list-style-type: none;
    margin: 10px 0 0 0;
    padding: 0 0 0 20px;
    .transition;
    @media @tablet-min {
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
        position: absolute;
        top: 35px;
        left: -20px;
        background-color: white;
        border-radius: 5px;
        padding: 10px 20px;
        z-index: 11;
        box-shadow: 0 10px 30px -5px rgba(0, 0, 0, .2);
        min-width: 200px;
        &:before {
            content: '';
            position: absolute;
            top: -25px;
            left: 20px;
            width: 80px;
            // background: red;
            height: 25px;
            display: block;
        }
    }

}
.nav__dropdown-item {
    text-decoration: none;
}
.nav__dropdown-link {
    text-decoration: none;
    font-size: 1rem;
    color: grey;
    .transition;
    &:hover {
        color: @c-red;
    }
}
.nav__item--has-dropdown {
    &:hover {
        +.nav__dropdown {
            display: block;
        }
    }
}
.nav__item-parent {
    position: relative;
}
.nav__icon {
    color: white;
    font-size: .8rem;
}
