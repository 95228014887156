.button {
    background-color: @c-main;
    color: #FFF;
    border: 0;
    padding: 10px 20px;
    outline: none;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    display: inline-block;
    min-width: 120px;
    font-size: .75rem;
    text-align: center;
    letter-spacing: 1px;
    cursor: pointer;
    .transition;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
    }
    &:hover {
        text-decoration: none;
        // color: #FFF;
        // background-color: @c-main;
        &:after {
            opacity: .1;
        }
    }
    &--project {
        display: block;
        background-color: #ebebeb;
        color: #b51917;
        font-weight: 600;
        margin: 25px 0;
        font-size: 1rem;
        &:hover {
            background-color: #b51917;
            color: #FFF;
        }
    }
}

.button--ghost {
    background: none;
    border: 1px solid @c-main; 
}
