﻿body.Education {
    .body-color(@c-education);
}

body.Office {
    .body-color(@c-office);
}

body.Retail {
    .body-color(@c-retail);
}

body.Healthcare {
    .body-color(@c-healthcare);
}

body.Leisure {
    .body-color(@c-leisure);
}

body.Residential {
    .body-color(@c-residential);
}

body.How-we-do-it{
    .body-color(@c-brand-red);
}

/*body.standardContentPage {
    .body-color(@c-brand-red);
}*/

.panel-grid__panel.Education {
    .panel-colour(@c-education);
}

.panel-grid__panel.Healthcare {
    .panel-colour(@c-healthcare);
}

.panel-grid__panel.Leisure {
    .panel-colour(@c-leisure);
}

.panel-grid__panel.Residential {
    .panel-colour(@c-residential);
}

.panel-grid__panel.Retail {
    .panel-colour(@c-retail);
}

.panel-grid__panel.Office {
    .panel-colour(@c-office);
}

.panel-colour(@color: @c-light-grey) {
    .panel-background {
        background-color: @color;
        opacity: .8;
    }
    .project-sector {
        color: @color;
    }
    &:hover {
        color: @color;
    }
}

.body-color(@color: @c-light-grey) {
    .main-banner--project__background {
        background-color: @color;
    }
    .project-info__heading {
        color: @color;
    }
    .LargeText {
        color: @color;
    }
    .main-banner {
        background-color: @color;
    }
    .intro-text {
        color: @color;
    }
    .IntroText {
        color: @color;
    }
    .panel--image-block {
        .panel-body {
            background-color: fade(@color, 85%);
        }
    }
    .project-info__item {
        .project-info__icon {
            fill: @color  !important;
        }
        .project-info__icon--text {
            fill: @color  !important;
        }
        .project-info__icon--noFill {
            stroke: @color;
            fill: none;
        }
    }
    .breadcrumb-item {
        &:last-of-type:not(:first-of-type) {
            background-color: @color;
            border-color: @color;
            .breadcrumb-text {
                color: white;
            }
        }
    }
}

.panel-body.Education {
    .panelBody-color(@c-education);
}

.panel-body.Office {
    .panelBody-color(@c-office);
}

.panel-body.Retail {
    .panelBody-color(@c-retail);
}

.panel-body.Healthcare {
    .panelBody-color(@c-healthcare);
}

.panel-body.Leisure {
    .panelBody-color(@c-leisure);
}

.panel-body.Residential {
    .panelBody-color(@c-residential);
}

.panelBody-color(@color: @c-light-grey) {
    /*.panel-body {*/
    background-color: fade(@color, 85%);
    /*}*/
}

.text-block__bg-heading.Education {
    .text-block__bg-color(@c-education);
}

.text-block__bg-heading.Office {
    .text-block__bg-color(@c-office);
}

.text-block__bg-heading.Retail {
    .text-block__bg-color(@c-retail);
}

.text-block__bg-heading.Healthcare {
    .text-block__bg-color(@c-healthcare);
}

.text-block__bg-heading.Leisure {
    .text-block__bg-color(@c-leisure);
}

.text-block__bg-heading.Residential {
    .text-block__bg-color(@c-residential);
}

.text-block__bg-heading.Brand-red {
    .text-block__bg-color(@c-brand-red);
}

.text-block__bg-heading.Brand-black {
    .text-block__bg-color(@c-brand-black);
}

.text-block__bg-heading.Brand-grey {
    .text-block__bg-color(@c-brand-grey);
}

.text-block__bg-color(@color: @c-light-grey) {
    .block-heading {
        color: @color;
    }
    .block-header {
        border-top: solid @color 2px;
        border-bottom: solid @color 2px;
    }
}
