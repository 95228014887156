﻿
.article-list__container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.article-list__panel {
    flex: 0 1 ~"calc(33.33333% - 20px)";
    margin: 0 10px 30px;
    position: relative;
    text-align: left;
}


.article-list__panel {
    box-shadow: 0 0 25px 0 rgba(0,0,0,.05);
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    @media @tablet {
        flex: 0 1 ~"calc(50% - 20px)";
    }
    @media @mobile {
        flex: 0 1 100%;
    }

    &:hover {
        background-color: #f9f9f9;
    }
}

.article-list__panel-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.article-list__date {
    // opacity: .7;
    display: block;
    margin-top: auto;
    color: #919EA5;
    // font-weight: 600;
}

.article-list__img {
    height: 150px;
    object-fit: cover;
    background-color: #eee;
    display: block;
    width:100%;
    margin: 0;
    object-fit: cover;
}

.article-list__title {
    // padding: 20px;
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.4;
    margin: 0 0 10px;
}
