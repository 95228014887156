﻿.project-info__panel
{
   
}
.project-info__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 0px;
    list-style-type: none;
    margin: 0 -@layout-padding;
    @media @desktop {
        justify-content: center;
    }

    @media @tablet{
        justify-content:flex-start;
    }
    @media @mobile {
        // width: 100%;
        padding: 0px 0px 15px 0px;
        flex-direction: column;
        padding-top: 15px;
    }
}
.project-info__item {
    background-color: #EAF0F2;
    padding: 20px;
    /*max-width: 440px;*/
    min-height: 160px;
    flex: 0 1 ~"calc(33.33333% - (@{layout-padding} * 2))";
    margin: 0 @layout-padding (@layout-padding * 2);
    color: #3E3B37;
    flex-wrap: wrap;
    /*max-width: 340px;*/
    @media @desktop {
        margin-bottom: 20px;
    }
    @media @tablet {
        flex: 0 1 ~"calc(50% - (@{layout-padding} * 2))";
        min-height: 135px;
        // max-width: 300px;
    }
    @media @mobile {
        flex: 0 1 ~"calc(100% - 10px)";
        min-height: 110px;
        // max-width: 500px;
        // margin-left: 50px;
    }
}
.project-info__header {
    border-bottom: 1.5px solid white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.project-info__heading {
    font-size: 0.9375rem;
    font-weight: 700;
    margin: 0;
    color: #3E3B37;
    text-transform: uppercase;
    color: #4A6E76;
}
.project-info__content{
    
}
.project-info__text{
    color:black;
   font-weight:400;
   font-size:2.1875rem;
   line-height:2.125rem; 
   margin:0;
   @media @tablet {
       font-size: 1.9rem;
       line-height: 1.2;
   }
   @media @mobile {
       font-size: 1.8rem;
   }
}