.social-buttons {
    margin: 0 0 10px;
}

.social-buttons__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.social-buttons__list-item {
    margin-right: 15px;
     
    &:last-of-type {
        margin-right: 0;
    }
}

.social-buttons__link {
    display: block;
    background-color: #9B9A9A;
    color: #FFF;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    text-decoration: none;
}

.social-buttons__icon {
}
