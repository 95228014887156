﻿.project{


    .text-block__bg {
        padding: 50px;
        min-height: 300px;
        font-weight: bold;
        background-color: #EAF0F2;
        text-align:left;
        @media @tablet {
            padding: 30px;
            min-height: 0;
        } 

        p {
            font-size: 1.5rem;
            @media @tablet-min {
                text-align:left;
            }
            @media @mobile {
                font-size: 1.2rem;
            }
        }

        li {
            font-size: 1.5rem;
        }
        a{
            word-break:break-all;
            display:inline-block;
        }

        @media @mobile{
            padding:30px;
        }
    }
    }
.text-block__bg {
    padding: 25px;
    min-height: 300px;
    background-color: #fecfcc;
    text-align: left;
    line-height: 1.5rem;

    @media @tablet {
        padding: 10px;
        min-height: 0;
    }

    h3 {
        font-size: 1.25rem;
        color: #b51917;
        font-weight: bold;
    }

    p {
        font-size: 1.25rem;
        color: #b51917;

        @media @tablet-min {
            text-align: left;
        }

        @media @mobile {
            font-size: 1.2rem;
        }
    }

    li {
        font-size: 1.5rem;
    }

    a {
        word-break: break-all;
        display: inline-block;
    }

    @media @mobile {
        padding: 30px;
    }
}


.block-header {
    border-top: solid red 2px;
    border-bottom: solid red 2px;
    margin-bottom: 20px;
}

    .block-text__container {
        max-width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .block-heading {
        font-weight: bold;
        font-size: 2.25rem;
        line-height:2.5rem;
        /*margin-top: 10px;
        margin-bottom: 15px;*/
    }


.text-block__bg-heading {
    padding: 50px;
    min-height: 300px;
    font-weight: bold;
    background-color: #EAF0F2;

    @media @mobile {
        padding: 30px;
    }

    p {
        font-size: 1.5rem;
        line-height: 1.75rem;

        @media @tablet-min {
            text-align: justify;
        }

        @media @mobile {
            font-size: 1.2rem;
        }
    }

    li {
        font-size: 1.5rem;
    }

    a {
        word-break: break-all;
        display: inline-block;
    }

    @media @mobile {
        padding: 30px;
    }
}