img {
    display: block;
    max-width: 100%;
    margin-bottom:20px;
    color: transparent;
}

// picture {
//     width: 100%;
//     height: 100%;
// }

.img--full {
    flex: 1;
    align-self: normal;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.content-img {
    width: 100%;
    
} 
 
.content-img--bg { 
    background-size: cover;
    padding-top: 45%;
    background-repeat: no-repeat;
}

.content-heading {
    &--large {
        font-weight: 600;
        text-transform: uppercase;
    }
}

.IntroText {
    color: #F90F00;
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 30px;
    line-height: 2.25rem;
    text-align: justify;

    @media @tablet {
        text-align: left;
        line-height: 2.3rem;
    }

    @media @mobile {
        font-size: 1.3rem;
        line-height: 1.1rem;
    }
}

.text-block__bg {
    padding: 30px;
    min-height: 300px;
    font-weight: bold;
}
.LargeText{
    font-size:2.8rem;
    line-height:3.4375rem; 
    font-weight:100;
    text-align:left; 
    // word-spacing: 10px;
    @media @tablet {
        font-size: 2.4rem;
    }
    @media @mobile{
        font-size: 2rem;
    }
}
.icon{
    min-height:20px;
    width:20px;
}

@media @tablet-min {
    .rte-text{
        text-align:justify; 
    }
}

.image-overlay {
    img {
        object-fit: cover;
        width: 100%;
        @media @tablet-min {
            margin-bottom: 0;
        }
    }
}
.media-image{
    img{
        @media @tablet{

        width:100%; 
        margin-top:20px;
        }
    }
}
.image-with-options {
    img {
        @media @tablet {
            width: 100%;
            margin-top:20px;
        }
    }
}