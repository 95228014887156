body.ie--10, body.ie--11 {
    .grid-container {
        width: auto;
        padding: 0;
        height: auto !important;
        max-height: none;
    }

    .grid {
        &:not(.grid--top) {
            display: none;
        }

        display: block;
        position: relative;
    }

    .grid__item {
        min-height: 100px;
    }



    .panel--cta {
        height: 35vw;
    }

    .project-image {
        min-height: 600px;
    }

    .row {
        flex-wrap: nowrap;
    }

    .news__panels-list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
    }

    .news__panels-item {
        flex: 1 1 35%;
        margin: 0 15px 30px 20px;
        min-height: 340px;
        max-width: 500px;

        &:nth-of-type(4) {
            background-image: url("/img/NewsBackgroundIE.png");

            .news__content {
                max-height: 290px;
                min-height: 290px;
            }
        }
    }

    .panel-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -@layout-padding;
    }

    .panel-grid__panel {
        flex: 1 1 47%;
        margin: 0 15px 30px 15px;
        max-height: 370px;
    }

    .article-list__panel {
        flex: 0 1 31%;
        min-height: 300px;
    }

    .search-icon {
    }

    .project-panel__item {
        flex: 0 1 30%;
    }

    .project-info__item {
        flex: 0 1 28.5%;
        max-width: 345px;
    }

    .team-members__list {
        margin: 0 -15px;
    }

    .team-members__item {
        flex: 0 1 30%;
        margin: 0 15px 30px 15px;
    }

    .nav__container{
        margin-left:-40px;
    }
}
