
.row {
  width: auto;
  margin: 0 -@layout-padding;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: @layout-padding * 2;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.row--reverse {
  flex-direction: row-reverse;
}

.row--justify-right {
  justify-content: flex-end;
}

.container {
  width: 100%;
  max-width: @container-width;
  margin: 0 auto;
  position: relative;
  padding: 0 @layout-padding;
  // background: red;
  @media @desktop {
    padding: 0 30px;
  }
  @media @mobile {
    padding: 0 20px;
  }
  &--static {
    position: static;
  }
  &--max-width {
    max-width: 800px !important;
  }
  &--fluid {
    max-width: none;
  }
  &--half {
    max-width: @container-width / 2;
    margin: 0;
  }
}

// umbraco grid hack
// .col-6 {
//   &:first-child {
//     .panel {
//       display: flex;
//       justify-content: flex-end;
//     }
//   }
//   .container--half {
//     padding: 0 60px;
//     @media @tablet {
//       padding: 0 30px;
//     }
//   }
// }

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

// Target all cols
[class*='col-'] {
  padding: 0 @layout-padding;
  position: relative;
  min-height: 1px;
  // flex: 0 0 auto;
  @media @tablet {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
}

// cols
.col {
  flex: 1;
}
.col-1 {
  flex-basis: (100% / 12);
}
.col-2 {
  flex-basis: (100% / 12 * 2);
}
.col-3 {
  flex-basis: (100% / 12 * 3);
}
.col-4 {
  flex-basis: (100% / 12 * 4);
}
.col-5 {
  flex-basis: (100% / 12 * 5);
}
.col-6 {
  flex-basis: (100% / 12 * 6);
  max-width: 50%;
}
.col-7 {
  flex-basis: (100% / 12 * 7);
}
.col-8 {
  flex-basis: (100% / 12 * 8);
}
.col-9 {
  flex-basis: (100% / 12 * 9);
}
.col-10 {
  flex-basis: (100% / 12 * 10);
}
.col-11 {
  flex-basis: (100% / 12 * 11);
}
.col-12 {
  flex-basis: 100%;
}


// desktop
.desktop-1 {
  @media @desktop {
    flex-basis: (100% / 12 * 1);
  }
}
.desktop-2 {
  @media @desktop {
    flex-basis: (100% / 12 * 2);
  }
}
.desktop-3 {
  @media @desktop {
    flex-basis: (100% / 12 * 3);
  }
}
.desktop-4 {
  @media @desktop {
    flex-basis: (100% / 12 * 4);
  }
}
.desktop-5 {
  @media @desktop {
    flex-basis: (100% / 12 * 5);
  }
}
.desktop-6 {
  @media @desktop {
    flex-basis: (100% / 12 * 6);
  }
}
.desktop-7 {
  @media @desktop {
    flex-basis: (100% / 12 * 7);
  }
}
.desktop-8 {
  @media @desktop {
    flex-basis: (100% / 12 * 8);
  }
}
.desktop-9 {
  @media @desktop {
    flex-basis: (100% / 12 * 9);
  }
}
.desktop-10 {
  @media @desktop {
    flex-basis: (100% / 12 * 10);
  }
}
.desktop-11 {
  @media @desktop {
    flex-basis: (100% / 12 * 11);
  }
}
.desktop-12 {
  @media @desktop {
    flex-basis: 100%
  }
}

// tablet
.tablet-1 {
  @media @tablet {
    flex-basis: (100% / 12 * 1);
  }
}
.tablet-2 {
  @media @tablet {
    flex-basis: (100% / 12 * 2);
  }
}
.tablet-3 {
  @media @tablet {
    flex-basis: (100% / 12 * 3);
  }
}
.tablet-4 {
  @media @tablet {
    flex-basis: (100% / 12 * 4);
  }
}
.tablet-5 {
  @media @tablet {
    flex-basis: (100% / 12 * 5);
  }
}
.tablet-6 {
  @media @tablet {
    flex-basis: (100% / 12 * 6);
  }
}
.tablet-7 {
  @media @tablet {
    flex-basis: (100% / 12 * 7);
  }
}
.tablet-8 {
  @media @tablet {
    flex-basis: (100% / 12 * 8);
  }
}
.tablet-9 {
  @media @tablet {
    flex-basis: (100% / 12 * 9);
  }
}
.tablet-10 {
  @media @tablet {
    flex-basis: (100% / 12 * 10);
  }
}
.tablet-11 {
  @media @tablet {
    flex-basis: (100% / 12 * 11);
  }
}
.tablet-12 {
  @media @tablet {
    flex-basis: 100%;
  }
}


// mobile
.mobile-1 {
  @media @mobile {
    flex-basis: (100% / 12 * 1);
  }
}
.mobile-2 {
  @media @mobile {
    flex-basis: (100% / 12 * 2);
  }
}
.mobile-3 {
  @media @mobile {
    flex-basis: (100% / 12 * 3);
  }
}
.mobile-4 {
  @media @mobile {
    flex-basis: (100% / 12 * 4);
  }
}
.mobile-5 {
  @media @mobile {
    flex-basis: (100% / 12 * 5);
  }
}
.mobile-6 {
  @media @mobile {
    flex-basis: (100% / 12 * 6);
  }
}
.mobile-7 {
  @media @mobile {
    flex-basis: (100% / 12 * 7);
  }
}
.mobile-8 {
  @media @mobile {
    flex-basis: (100% / 12 * 8);
  }
}
.mobile-9 {
  @media @mobile {
    flex-basis: (100% / 12 * 9);
  }
}
.mobile-10 {
  @media @mobile {
    flex-basis: (100% / 12 * 10);
  }
}
.mobile-11 {
  @media @mobile {
    flex-basis: (100% / 12 * 11);
  }
}
.mobile-12 {
  @media @mobile {
    flex-basis: 100%;
  }
}



