﻿.homepage__panels {
    background-color: #DA8B8A;
    padding: 30px 0;
    padding-top:40px;
    @media @mobile {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .panel--block-title {
        color: white;
    }
}

.homepage__panels-list {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding-left: 0;
    width: 100%;
    margin: 0;
    @media @tablet {
        display: block;
    }
}

.homepage__panels-item {
    flex: 1 1 33.333%;
    margin-right: 25px;
    position: relative;
    min-height: 160px;
    background-size: cover;
    color: white;
    padding: 20px;
    background-repeat: no-repeat;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.2)
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        .transition;
    }
    &:hover {
        &:after {
            opacity: .2;
        }
    }
    @media @tablet {
        margin-bottom: 20px;
        margin-right: 0;
    }
    h3 {
        font-weight: 600;
        font-size: 1.5rem;
    }
    &:nth-of-type(1) {
        background-color: red;
        .homepage-panels__overlay {
            background-color: #f90f00;
            opacity: .7;
        }
    }
    &:nth-of-type(2) {
        // color: #BA1919;
        border: solid 20px rgba(249, 15, 0, 0.8);
        /*box-shadow: 0px 0px 0px 20px rgba(249,15,0,0.8);*/
        .homepage-panels__content {
            padding-top: 5px;
        }
    }
    &:nth-of-type(3) {
        color: #B51816;
        background-color: #FFF;
        margin-right: 0;
        background-position: 0 37px;
        &:before {
            display: none;
        }
    }
}

.homepage__content {
    padding: 10px;
}
.homepage-panels__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
.homepage-panels__content {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 20px;
}
.gatewayPage{

.homepage__panels{
    margin-bottom:26px;
}

}