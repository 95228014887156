﻿.news__panels {
    background-color: #C5C5C4;
    margin-bottom: 0;
    padding: 30px 0;
    padding-top:40px;
    @media @mobile {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 40px;
    }
}
.news__panels-list {
    /*display:flex;*/
    /*justify-content:center;*/
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin: 0;
    padding: 0;
    width: 100%;
    @media @tablet {
        display: block;
    }
}
.news__panels-item {
    padding: 30px 50px;
    font-size: 1.375rem;
    color: white;
    position: relative;
    display: flex;
    @media @tablet {
        margin-bottom: 30px;
        min-height: 250px;
        max-height: 250px;
    }
    @media @mobile {
        margin-bottom: 30px;
        min-height: 250px;
        max-height: 250px;
        padding: 25px;
    }
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        opacity: 0;
        .transition;
    }
    &:hover {
        &:after {
            opacity: .2;
        }
    }
    &:nth-of-type(1) {
        grid-area: ~"1 / 1 / 3 / 1";
        background-color: #919EA4;
        min-height: 340px;
        @media @tablet {
            min-height: 250px;
            max-height: 250px;
        }
    }
    &:nth-of-type(2) {
        grid-area: ~"1 / 3 / 2 / 2";
        background-color: #536771;
        .news__display-date {
            color: white;
        }
    }
    &:nth-of-type(3) {
        grid-area: ~"2 / 3 / 3 / 2";
        background-color: #FFFFFF;
        color: #525D58;
    }
    &:nth-of-type(4) {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
        grid-area:~"1 / 3 / 3 / 3";
        background-image: url("/img/NewsBackgroundImg.svg");
        background-size: cover;
        @media @mobile {
            margin-bottom: 0;
        }
        .news__content {
            background-color: fade(#8F9EA4, 95%);
            padding: 20px;
            position: absolute;
            top: 30px;
            left: 30px;
            right: 30px;
            bottom: 30px;
        }
    }
}

.news__display-date {
    border-top: 1px solid #C5C5C4;
    color: #b51917;
    text-decoration: underline;
    padding-top: 5px;
    font-size: 0.9375rem;
    margin: auto 0 0 0;
    flex: 0 1 100%;
}

.news__content {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
    flex: 0 1 100%;
    align-items: flex-start;
}

.news__teaser-text {
    flex: 0 1 100%;
}