body {
    color: @c-body;
    padding: 0;
    margin: 0;
    font-family: @font-sans-serif;
    font-size: 16px;
    // overflow-x:hidden;
    &:after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.5);
        z-index: 9;
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
        .transition;
    }
}
.body--nav-open {
    overflow: hidden;
    &:after {
        opacity: 1;
        visibility: visible;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    // overflow-x: hidden;
    flex: 1;
    @media @tablet {
        padding-top: 120px;
    }
    @media @mobile {
        padding-top: 60px;
    }
}

.homePage {
    .wrapper {
        @media @tablet {
            padding-top: 0;
        }
    }
}

main {
    flex: 1 0 auto;
    /*padding-top: @header-height;*/
    height: 100%;
    // overflow-x:hidden;
    @media @tablet {
        /*padding-top: @header-height--tablet;*/
    }
}

.dark-bg {
    background-color: @c-grey;
    color: #FFF;
    border-bottom: 1px solid @c-main;
}



img {
    max-width: 100%;
}