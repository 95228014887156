﻿.search-icon__path {
    fill: #fff;
    fill-rule: evenodd;
}

.search-icon {
    width: 25px;
    height: 25px;
    background-color: #BA1919;
    padding: 4px;
    user-select: none;
    @media @tablet {
        display: none;
    }
    &:hover {
        cursor: pointer;
    }
    svg {
        pointer-events: none;
        width: 17px;
        height: 17px;
    }
}

.homePage {
    .search-icon__path {
        fill: #BA1919;
    }

    .search-icon {
        background-color: #fff;
    }
}

.search-container {
    position: absolute;
    right: 0;
    top: 97px;
    box-shadow: 0px 14px 30px -2px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 350px;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    will-change: transform;
    .transition;
    @media @tablet {
        position: fixed;
        top: auto;
        left: 0;
        bottom: 0;
        opacity: 1;
        transform: none;
        visibility: visible;
        max-width: none;
        box-shadow: none;
        border-top: 1px solid rgb(219, 219, 219);
        &:before {
            display: none;
        }
    }
    &--active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    &:before {
        content: '';
        position: absolute;
        top: -5px;
        right: 15px;
        height: 20px;
        width: 20px;
        background-color: #BA1919;
        transform: rotate(45deg);
    }
}

.search-container__form {
    display: flex;
}

.search-container__input {
    border: 0;
    padding: 15px;
    display: block;
    background-color: #FFF;
    font-family: @font-sans-serif;
    flex: 1;
    outline: none;
    border-radius: 5px 0 0 5px;
    @media @tablet {
        border-radius: 0;
    }
}

.search-container__button {
    appearance: none;
    border: 0;
    color: #FFF;
    background-color: #BA1919;
    padding: 10px 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: @font-sans-serif;
    border-radius: 0 5px 5px 0;
    cursor: pointer;
    @media @tablet {
        border-radius: 0;
    }
}

@media @tablet-min {
    .homePage {
        .search-container {
            &:before {
                background-color: grey;
            }
        }
        .search-container__button {
            background-color: grey;
        }
    }
}

.search-result__list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.search-result__listing {
    border-bottom: 2px solid #eee;
    // padding-bottom: 20px;
    // padding-left: 30px;
    padding: 20px 0 20px 30px;
    position: relative;
    display: block;
    &:before {
        content: '\f054';
        font-family: 'FontAwesome';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        font-size: .7rem;
    }
    &:last-of-type {
        border: 0;
    }
}