// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Light.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Light.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 300;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Regular.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Regular.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Regular.ttf') format('truetype');
//   font-weight: 400;
//   font-display: swap;
// }
// @font-face {
//   font-family: 'Basis';
//   src: url('/fonts/BasisGrotesqueMonoPro-Bold.woff2') format('woff2'), url('/fonts/BasisGrotesqueMonoPro-Bold.woff') format('woff'), url('/fonts/BasisGrotesqueMonoPro-Bold.ttf') format('truetype');
//   font-weight: 800;
//   font-display: swap;
// }

h1,
h2,
h3,
h4,
h5 {
  margin: 30px 0;
  font-weight: 300;
  line-height: 1.4;
  &:first-of-type {
    margin-top: 0;
  }
  &:empty {
    margin: 0;
  }
}

h1 {
  font-size: 2.4rem;
  font-weight: 400;
  margin: 40px 0;
}

h3 {
  font-size: 1.7rem;
}

p {
  margin: 30px 0;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 400;
  &:only-of-type {
    margin: 0;
  }
  &:first-of-type {
    margin-top: 0;
  }
  &:empty {
    margin: 0;
  }
  @media @tablet {
    text-align: left !important;
  }
}

li {
  font-size: 1.25rem;
  line-height: 1.5;
}

a {
    color: #b51917;
    font-weight: 600;
}

.intro-text {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 30px;
    line-height: 1.3;
    text-align: justify;
    color:red;
    // color: @c-brand-red;



     p{
        &:not(:first-of-type){
            color:black ; 
            font-weight:400;
        }
        
    
        font-weight: bold;
        font-size: 2rem;
        margin-bottom: 30px;
        line-height: 2.25rem;
    }
}
