﻿.panel-grid {
    display: grid;
    grid-gap: 25px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: repeat(4, 1fr);
    margin: 0;
    @media @tablet {
        // grid-template-columns: 1fr 1fr 1fr;
        grid-auto-columns: auto;
    }
    @media @tablet {
        /*grid-template-columns: auto;*/
        display: block;
    }
}
.panel-background {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: yellow;
    opacity: 0.5;
    display: none;
    .transition--long;
}
.panel-grid__panel {
    position: relative;
    overflow: hidden;
    background-color: #C3C4C4;
    background-size: cover;
    background-repeat: no-repeat;
    /*border-radius: 5px;*/
    // grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #FFF;
    min-height: 340px;
    max-height: 340px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        pointer-events: none;
        opacity: 0.2;
    }

    &:hover {
        // cursor: pointer;
        // font-weight: bold !important;
        // transition: all .3s ease;
        // z-index: 2;
        .panel__content {
            &:before {
                transform: scale(1);
                opacity: 1;
            }
        }
    }

    @media @tablet {
        margin-bottom: 20px;
        min-height: 315px;
        max-height: 315px;
    }

    h3 {
        font-weight: 600;
        font-size: 1.5rem;
        z-index: 1;
        position: relative;
        line-height: 1.625rem;;
    }
    /*.project-sector{
        position:absolute;
        bottom:25px;
        left:25px;
        text-decoration:underline; 
    }*/
    &:nth-of-type(1) {
        grid-area: ~"1 / 1 / 3 / 2";
    }

    &:nth-of-type(2) {
        grid-area: ~"1 / 2 / 2 / 4";
    }

    &:nth-of-type(3) {
        grid-area: ~"2 / 1 / 3 / 2";
    }

    &:nth-of-type(4) {
        grid-area: ~"2 / 2 / 3 / 3";
    }

    &:nth-of-type(5) {
        grid-area: ~"2 / 3 / 3 / 4";
    }

    &:nth-of-type(6) {
        grid-area: ~"3 / 1 / 5 / 3";
        max-height: 705px;

        @media @tablet {
            min-height: 315px;
            max-height: 315px;
        }

        .panel__content {
            min-height: 650px;
        }
    }

    &:nth-of-type(7) {
        grid-area: ~"3 / 3 / 4 / 4";
    }

    &:nth-of-type(8) {
        grid-area: ~"4 / 3 / 5 / 4";
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #FFF;
        pointer-events: none;
        opacity: 0;
        .transition;
    }

    .panel__content {
        min-height: 290px;
        /*padding: 25px;
        padding-top:30px;*/
        padding: 20px 15px 20px 15px;
        // font-weight: 700 !important;
        margin: 25px;
        z-index: 10;
        .transition;

        &:before {
            content: '';
            position: absolute;
            top: 25px;
            right: 25px;
            bottom: 25px;
            left: 25px;
            background-color: #FFF;
            opacity: 0;
            transform: scale(.9);
            // z-index: -1;
            .transition--long;
        }
    }

    &:hover {
        &:before {
            opacity: 0;
        }
    }

    &:hover .panel-background {
        display: block;

        &:after {
            opacity: .15;
            background-color: yellow;
        }
    }

    &:hover .project-sector {
        display: block;
    }

    @media @mobile {
        grid-column: span 2;
    }
}
.project-sector {
    display: none;
    color: black;
    text-decoration: underline;
    font-size: 1.1rem;
    position: absolute;
    bottom: 40px;
}
.panel-grid__withBackground {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        pointer-events: none;
        opacity: 0.35;
    }
}
.c-sport--bg.panel-grid__withBackground {
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #000;
        pointer-events: none;
        opacity: 0.35;
    }
    color: #FFF;
}
// Sizes
.panel-grid__panel--large {
    grid-row: span 2;
    grid-column: span 2;
}
.panel-grid__panel--wide {
    grid-column: span 2;
}
.panel-grid__panel--tall {
    grid-row: span 2;
}
// Modifiers
.panel-grid__panel--logo {
    text-align: center;
    .panel-grid__heading {
        font-weight: 400;
    }
}
.panel-grid__content {
    flex: 1;
    margin-bottom: 10px;
}
.panel-grid__heading {
    line-height: 1.2;
    margin: 0;
    text-transform: uppercase;
    font-size: 1.625rem;
    line-height: 1.1;
}
.panel-grid__text {
    font-size: 1.2rem;
}
.panel-grid__date {
    font-size: 1.4rem;
}
.panel-grid__icon {
    width: 100%;
    max-width: 60px;
    align-self: center;
    margin-bottom: 10px;
}
.panel-grid__category {
    align-self: flex-start;
    background-color: #FFF;
    font-weight: 600;
    padding: 7px 10px;
    border-radius: 3px;
    font-size: 0.75rem;
    min-width: 81px;
    text-align: left;
    margin-top: 10px;
    color: @c-body;
    text-transform: uppercase;
    letter-spacing: 1px;
}
